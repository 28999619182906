import { Helmet } from 'react-helmet';
import MainMenu from '../Components/MainMenu';
import MobileMenu from '../Components/MobileMenu';
import Header from '../Components/Header';
import Hello from '../Components/Hello';
import Resume from '../Components/Resume';
import Certifications from '../Components/Certifications';
import LatestBlog from '../Components/LatestBlog';
import Contact from '../Components/Contact';
import CertificationModal from '../Components/CertificationModal';

const Home = () => {
  return (
    <>
      {/* <!--Main menu--> */}
      <MainMenu />
      {/* <!--Main menu--> */}

      {/* <!-- Mobile menu --> */}
      <MobileMenu />
      {/* <!-- Mobile menu --> */}

      {/* <!--Header--> */}
      <Header />
      {/* <!--Header--> */}

      {/* <!--Hello--> */}
      <Hello />
      {/* <!--Hello--> */}

      <hr />

      {/* <!--Resume--> */}
      <Resume />
      {/* <!--Resume--> */}

      {/* <!--Certifications--> */}
      <Certifications />
      {/* <!--Certifications--> */}

      {/* <!--Blog--> */}
      <LatestBlog />
      {/* <!--Blog--> */}

      {/* <!--Contact--> */}
      <Contact />
      {/* <!--Contact--> */}

      {/* <!-- CertificationModal --> */}
      <CertificationModal />
      {/* <!-- CertificationModal --> */}
      <Helmet>
        <script src='assets/js/jquery-2.2.4.min.js'></script>
        <script src='assets/js/popper.min.js'></script>
        <script src='assets/js/bootstrap.min.js'></script>
        <script src='assets/js/menu.js'></script>
        <script src='assets/js/jquery.waypoints.js'></script>
        <script src='assets/js/progress-list.js'></script>
        <script src='assets/js/section.js'></script>
        <script src='assets/js/portfolio-filter.js'></script>
        <script src='assets/js/slider-carousel.js'></script>
        <script src='assets/js/mobile-menu.js'></script>
        <script src='assets/js/contacts.js'></script>
        <script src='assets/js/mbclicker.min.js'></script>
        <script src='assets/js/site-btn.js'></script>
        <script src='assets/js/style-switcher.js'></script>
      </Helmet>
    </>
  );
};

export default Home;

