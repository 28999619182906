import { useState, useEffect } from 'react';
import Moment from 'react-moment';

const LatestBlog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const query = `
    {
      user(username: "mikaeelkhalid") {
        publication {
          posts{
            slug
            title
            brief
            coverImage
            dateAdded
          }
        }
      }
    }
  `;

  const fetchPosts = async () => {
    try {
      const res = await fetch('https://api.hashnode.com', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ query }),
      });
      const apiData = await res.json();

      setPosts(apiData.data.user.publication.posts.slice(0, 3));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(posts);
  useEffect(() => {
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section id='blog' className='container section'>
        <div className='row'>
          <div className='col-md-12'>
            <h2 id='blog_header' className='section__title'>
              Latest from Blog_
            </h2>
            {/* <p className='post-cards_description'>view all</p> */}
          </div>
        </div>

        <div className='row post-cards'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            posts.map((post, i) => (
              <div className='col-md-4' key={i}>
                <a
                  href={'https://blog.mikaeels.com/' + post.slug}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='post-cards__card'>
                    <div className='post-cards__img'>
                      <img src={post.coverImage} alt='blog_img' />
                    </div>
                    <div className='post-cards__info'>
                      <p className='post-cards__date'>
                        <Moment format='DD, MM, YYYY'>{post.dateAdded}</Moment>{' '}
                      </p>
                      <h3 className='post-cards_title'>
                        {post.title.substring(0, 65)}...
                      </h3>
                      {/* <p className='post-cards_description'>{post.brief}</p> */}
                    </div>
                  </div>
                </a>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};

export default LatestBlog;

