import React from 'react';
import Dot from "./Dot";

const Resume = () => {
  return (
    <>
      <section id='resume' className='container section'>
        <div className='row'>
          <div className='col-md-10'>
            <h2 id='resume_header' className='section__title'>
              Resume_
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8 section__resume resume-list'>
            <h3 className='resume-sub-title'>education</h3>
            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                <a href="https://www.nu.edu.pk/" target="_blank" rel="noopener noreferrer">National University of Computer and Emerging Sciences</a>
              </p>
              <p className='job-date'>2021</p>
              <p>Bachelor of Science - BS, Computer Science</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-8 section__resume resume-list'>
            <h3 className='resume-sub-title'>employment</h3>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                DevOps Engineer // <a href="https://www.rewaatech.com/en/" target="_blank" rel="noopener noreferrer">Rewaa Tech</a>
              </p>
              <p className='job-date'>Nov, 2022 - Present</p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Collaborating with 16 development teams to design, implement, and maintain the infrastructure and deployment processes for new and existing infrastructure.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Collaborated closely with the data engineering team to successfully implement Google Cloud Dataproc, a managed Spark and Hadoop service, to enhance data processing efficiency and contribute to more agile decision-making.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Designing and implementing infrastructure as code (IAC) using tools such as AWS CDK (Typescript, Python), CloudFormation, and Terraform.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Monitoring and troubleshooting production systems using CloudWatch and DataDog to ensure their stability, performance, cost efficiency, and availability.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Managing and optimizing AWS resources to
                ensure cost efficiency and availability.
              </p>
            </div>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                Cloud Engineer // <a href="#" target="_blank" rel="noopener noreferrer">Freelance</a>
              </p>
              <p className='job-date'>Mar, 2022 - Jun, 2023</p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />As a freelance contractor, successfully assisted a diverse clientele in optimizing their infrastructure on Google Cloud Platform (GCP) and Amazon Web Services (AWS), leveraging best practices to enhance performance, security, and cost-efficiency.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Orchestrated migration from Heroku to GCP with managed services like GKE, Google Cloud Storage, and BigQuery.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Led the design and implementation of a robust disaster recovery solution for Client on Amazon Web Services, incorporating multi-region redundancy and automated failover mechanisms, ensuring business continuity and reducing downtime by 95% during system maintenance.
              </p>
            </div>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                DevOps Engineer // <a href="https://northbaysolutions.com/" target="_blank" rel="noopener noreferrer">NorthBay Solutions LLC</a>
              </p>
              <p className='job-date'>June, 2021 - Oct, 2022 </p>

              <br />

              <h5 className='sub-job'>
                AWS DevOps Engineer
              </h5>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Part of the team where my role was to
                deploy whole infrastructure for Entrepreneurship Development
                Platform using AWS CDK, and create CICD for both Frontend and
                Backend apps.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Tech stack: Typescript, AWS CDK, VPC,
                AppSync, WAF, Cognito, ECS, ECR, RDS (Postgres), Docker, Lambda, API
                Gateway, S3, CloudFront, CodePipeline.{' '}
              </p>

              <br />
              <h5 className='sub-job'>
                AWS IoT Engineer
              </h5>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Part of the team where we successfully delivered a POC in collaboration with AWS Team to get
                Airport Carousels Sensors data into AWS IoT Platform and implement the Predictive Analytics Solution for Baggage Handling System. <a href="https://www.aci-asiapac.aero/media-centre/news/riyadh-airports-records-a-new-achievement-by-adding-artificial-intelligence-ai-technologies-to-enhance-baggage-handling-systems-at-king-khalid-international-airport-in-cooperation-with-amazon-aws-and-airis" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Read more...</a>
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Successfully spawned the whole AWS IoT
                infrastructure using AWS CDK, and created CICD for CDK Script
                and L4E Model.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Tech stack: Python, Cloudrail, AWS CDK, Terraform, IoT Core, IoT Events, IoT Analytics, Quicksight, L4E, Lambda,
                S3, SNS.
              </p>
            </div>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                Teacher Assistant // <a href="https://www.nu.edu.pk/" target="_blank" rel="noopener noreferrer">National University of Computer and
                  Emerging Sciences</a>
              </p>
              <p className='job-date'>Feb, 2021 - Jun, 2021</p>
              <p style={{ fontSize: '14px' }}>
                <Dot />Teacher assistant for Web Programming.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />My responsibilties including, to help
                teacher in check assignments and quizez.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Tech stack: Html, CSS, Javascript,
                React, Redux, Nodejs, PHP, SQL.
              </p>
            </div>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                Associate MERN Stack Engineer // <a href="https://productbox.dev/" target="_blank" rel="noopener noreferrer">Productbox</a>
              </p>
              <p className='job-date'>Nov, 2020 - Apr, 2021</p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Work with customers/product managers to
                define, build out, test, and release features and products.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Partner with customers and cross-cutting
                teams to ensure projects are delivered as committed and per the
                expectation of customers/product managers.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Develop a core application of clients in
                React.js/Node.js Framework.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Write automated test cases of high
                quality.
              </p>
            </div>

            <div className='resume-list__block'>
              <p className='resume-list__block-title'>
                Full Stack Developer // <a href="http://pwr.nu.edu.pk/" target="_blank" rel="noopener noreferrer">National University of Computer and
                  Emerging Sciences</a>
              </p>
              <p className='job-date'>Aug, 2019 - May, 2020</p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Was part of the media team & development
                to develop and maintain Fast - National University of Computer
                and Emerging Sciences Peshawar campus portal.
              </p>
              <p style={{ fontSize: '14px' }} className='text-justify'>
                <Dot />Tech stack: JavaScript, JSON, MySQL,
                PHP, jQuery, Bootstrap
              </p>
            </div>
          </div>
        </div>
        <div className='row section__resume progress-list js-progress-list'>
          <div className='col-md-12'>
            <h3 className='resume-sub-title'>Technical skills</h3>
          </div>
          <div className='col-md-5 mr-auto'>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  Html, CSS, Tailwind, Bootstrap, Material UI.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  JavaScript, Typescript.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  React.js, Vue.js., Redux, Redux-Sagas, Redux-Toolkit
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  Node.js, Python, Java, PHP.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  Express.js, Django, Laravel.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  PostgreSQL, MySQL.
                </span>
              </p>
            </div>
          </div>
          <div className='col-md-5 mr-auto'>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  NoSQL, MongoDB.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  CI/CD Pipelines, GitHub, Bitbucket, GitLab.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  Docker, Puppet, Jenkins, Ansible.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  AWS CDK, Serverless Framework, Terraform.
                </span>
              </p>
            </div>
            <div className='progress-list__skill'>
              <p>
                <span className='progress-list__skill-title'>
                  MacOS, Linux, Windows.
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;

