import React from 'react';

const Header = () => {
  return (
    <>
      <header
        className='main-header'
        // style={{ backgroundImage: `url(assets/img/IMG_3249.png)` }}
      >
        <div className='container'>
          <div className='row personal-profile'>
            <div className='col-md-4 personal-profile__avatar'>
              <img src='assets/img/IMG_3249.png' className='rounded-lg' alt='avatar' />
            </div>
            <div className='col-md-8'>
              <p className='personal-profile__name'>Mikaeel Khalid_</p>
              <p className='personal-profile__work'>DevOps Engineer</p>
              <div className='personal-profile__contacts'>
                <dl className='contact-list contact-list__opacity-titles'>
                  <dt>Hashnode:</dt>
                  <dd>
                    <a href='https://hashnode.com/@mikaeelkhalid'>@mikaeelkhalid</a>
                  </dd>
                  <dt>Dev.to:</dt>
                  <dd>
                    <a href='https://dev.to/mikaeelkhalid'>mikaeelkhalid</a>
                  </dd>
                  <dt>Email:</dt>
                  <dd>
                    <a href='mailto:mikaeelkhalid@icloud.com'>mikaeelkhalid@icloud.com</a>
                  </dd>
                  <dt>Working:</dt>
                  <dd>404</dd>
                </dl>
              </div>
              <p className='personal-profile__social'>
                <a href='https://github.com/mikaeelkhalid' rel='noreferrer' target='_blank'>
                  <i className='fa fa-github'></i>
                </a>
                <a href='https://www.linkedin.com/in/mikaeelkhalid/' rel='noreferrer' target='_blank'>
                  <i className='fa fa-linkedin-square'></i>
                </a>
                <a href='https://www.mikaeels.com/' rel='noreferrer' target='_blank'>
                  <i className='fa fa-globe'></i>
                </a>
                <a href='https://blog.mikaeels.com/' rel='noreferrer' target='_blank'>
                  <i className='fa fa-rss-square'></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

