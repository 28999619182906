import React from 'react';

const MainMenu = () => {
  return (
    <>
      <div className='menu'>
        <div className='container'>
          <div className='row'>
            <div className='menu__wrapper d-lg-block col-md-12'>
              <nav className=''>
                <ul>
                  <li>
                    <a href='#hello'>Hello</a>
                  </li>
                  <li>
                    <a href='#resume'>Resume</a>
                  </li>
                  <li>
                    <a href='#certifications'>Certifications</a>
                  </li>
                  <li>
                    <a href='#blog'>blog</a>
                  </li>
                  <li>
                    <a href='#contact'>Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className='menu__wrapper col-md-12 d-lg-none'>
              <button type='button' className='menu__mobile-button'>
                <span>
                  <i className='fa fa-bars' aria-hidden='true'></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainMenu;

