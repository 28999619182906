import React from 'react';

const Hello = () => {
  return (
    <>
      <section id='hello' className='container section'>
        <div className='row'>
          <div className='col-md-10'>
            <h2 id='hello_header' className='section__title'>
              Hi_
            </h2>
            <p className='section__description text-justify'>
              I am a DevOps engineer with a strong background in computer science and experience in various programming languages (Python, Go, JavaScript, Typescript). I am skilled at developing and maintaining software applications. In addition, I have expertise in using cloud platforms like Amazon Web Services (AWS) and Google Cloud Platform (GCP), and implementing DevOps practices to ensure efficient and reliable software deployment and infrastructure management. Whether it's building and deploying new applications or optimizing and maintaining existing systems, I have a passion for using my technical skills to drive business success. I am excited to bring my skills and experience to your team and contribute to the growth and success of your organization.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hello;

