import Home from './pages/Home';
// import NotFound from './pages/NotFound';
import { Routes, Route, Navigate } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<Navigate to='/' />} />
      {/* /* <Route path='/blog' element={<BlogPage />} /> */}
    </Routes>
  );
}

export default App;

