import React from 'react';

const Certifications = () => {
  return (
    <>
      <section id='certifications' className='container section'>
        <div className='row'>
          <div className='col-md-12'>
            <h2 id='portfolio_header' className='section__title'>
              My certifications_
            </h2>
          </div>
        </div>
        <div className='row portfolio-menu'>
          <div className='col-md-12'>
            <nav>
              <ul>
                <li>
                  <a href='/#' data-portfolio-target-tag='all'>
                    all
                  </a>
                </li>
                <li>
                  <a href='/#' data-portfolio-target-tag='aws'>
                    AWS
                  </a>
                </li>
                <li>
                  <a href='/#' data-portfolio-target-tag='gcp'>
                    GCP
                  </a>
                </li>
                <li>
                  <a href='/#' data-portfolio-target-tag='hashicorp'>
                    HashiCorp
                  </a>
                </li>
                <li>
                  <a href='/#' data-portfolio-target-tag='coursera'>
                    Coursera
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='portfolio-cards'>
          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='https://www.credly.com/badges/67345390-d8b1-4549-ab24-9ffc48c5709a/public_url'
            data-portfolio-tag='gcp'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/gcp-associate.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>Google Cloud Certified Associate Cloud Engineer</h3>
              <p className='project-card__description text-justify'>
                An Associate Cloud Engineer deploys applications, monitors operations, and manages enterprise solutions. This
                individual is able to use Google Cloud Console and the command-line interface to perform common platform-based
                tasks to maintain one or more deployed solutions that leverage Google-managed or self-managed services on Google
                Cloud.
              </p>
              <a
                href='https://www.credential.net/9ac2f9b7-7ce6-4c3e-99dd-ea8c6f342b7c#gs.223xqt'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credential
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='https://www.credly.com/badges/67345390-d8b1-4549-ab24-9ffc48c5709a/public_url'
            data-portfolio-tag='aws'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/aws-devops.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>AWS Certified DevOps Engineer – Professional</h3>
              <p className='project-card__description text-justify'>
                Earners of this certification have an extensive understanding of implementing continuous delivery systems and
                methodologies on the AWS Cloud. They demonstrated the ability to automate resilient applications and
                infrastructure deployments using AWS services. Badge owners are able to enforce policies, implement automated
                monitoring, and configure event management of an environment.
              </p>
              <a
                href='https://www.credly.com/badges/f924dbe3-0780-4405-849a-06903830f968/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='https://www.credly.com/badges/67345390-d8b1-4549-ab24-9ffc48c5709a/public_url'
            data-portfolio-tag='hashicorp'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/terraform.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>HashiCorp Certified: Terraform Associate</h3>
              <p className='project-card__description text-justify'>
                Earners of the HashiCorp Certified: Terraform Associate certification know the basic concepts, skills, and use
                cases associated with open source HashiCorp Terraform. They understand and can utilize Terraform according to the
                certification objectives. Additionally, they understand why enterprises choose to extend Terraform Open Source
                with Terraform Enterprise to solve business critical objectives.
              </p>
              <a
                href='https://www.credly.com/badges/67345390-d8b1-4549-ab24-9ffc48c5709a/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>
          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='#portfolioModal'
            data-portfolio-tag='aws'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/aws-developer.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>AWS Certified Developer - Associate</h3>
              <p className='project-card__description text-justify'>
                Earners of this certification have a comprehensive understanding of application life-cycle management. They
                demonstrated proficiency in writing applications with AWS service APIs, AWS CLI, and SDKs; using containers; and
                deploying with a CI/CD pipeline. Badge owners are able to develop, deploy, and debug cloud-based applications that
                follow AWS best practices.
              </p>
              <a
                href='https://www.credly.com/badges/607e5bcf-359e-471f-8d74-4191d01bbdba/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='#portfolioModal'
            data-portfolio-tag='aws'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/aws-practioner.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>AWS Certified Cloud Practitioner</h3>
              <p className='project-card__description text-justify'>
                Earners of this certification have a fundamental understanding of IT services and their uses in the AWS Cloud.
                They demonstrated cloud fluency and foundational AWS knowledge. Badge owners are able to identify essential AWS
                services necessary to set up AWS-focused projects.
              </p>
              <a
                href='https://www.credly.com/badges/46d64a9c-a327-417d-b3f6-31fd7ca7c0d4/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='#portfolioModal'
            data-portfolio-tag='aws'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/serverless.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>AWS Learning: Serverless</h3>
              <p className='project-card__description text-justify'>
                Earners of this badge have developed technical skills and knowledge of AWS serverless concepts and services with a
                focus on AWS Lambda and Amazon API Gateway.
              </p>
              <a
                href='https://www.credly.com/badges/3da5e74a-075a-4189-a844-c893c3236ee7/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='#portfolioModal'
            data-portfolio-tag='aws'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/architecting.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>AWS Learning: Architecting</h3>
              <p className='project-card__description text-justify'>
                Earners of this badge have developed technical skills and knowledge of AWS concepts and services with a focus on
                designing solutions on AWS using best practices.
              </p>
              <a
                href='https://www.credly.com/badges/14df5df9-d9dd-4e76-a3d9-78f248f97834/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>

          <div
            className='row project-card'
            // data-toggle='modal'
            // data-target='#portfolioModal'
            data-portfolio-tag='coursera'>
            <div className='col-md-6 col-lg-5 project-card__img'>
              <img className='' src='assets/cert/google-it.png' alt='project-img' />
            </div>
            <div className='col-md-6 col-lg-7 project-card__info'>
              <h3 className='project-card__title'>Google IT Support Certificate</h3>
              <p className='project-card__description text-justify'>
                Those who earn the Google IT Support Certificate have completed five-courses, developed by Google, that include
                hands-on, practice-based assessments and are designed to prepare them for entry-level roles in IT support. They
                are competent in foundational skills, including troubleshooting and customer service, networking, operating
                systems, system administration, and security.
              </p>
              <a
                href='https://www.credly.com/badges/4956ffa2-d6fd-4046-addf-a06604514386/public_url'
                target='_blank'
                rel='noreferrer'
                className='project-card__link'>
                Credly
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Certifications;

